/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/constants.scss';
@import '/src/assets/theme/color.scss';

.navHeader {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
}

.header__top {
  display: flex;
  align-items: center;
}

.navBlackHeader {
  background: transparent;
}

.logoWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 50px;
  gap: 75px;
  height: 58px;
  border-radius: 100px;
  background-color: $white-bg;
}

.logoWarpImg {
  width: 150px !important;
  height: 26px !important;
  img {
    width: auto !important;
    height: 25.23px !important;
  }
}

.logoImg {
  width: 106px;
  height: auto;
}

.header__links {
  display: flex;
  align-items: center;
  gap: 40px;
  

  .header__out_link {
    display: inline-block;
    transition: fill 0.2s ease-in-out;

    &:hover {
      svg path {
        fill: $primary-text;
      }
    }
  }
}

.navSticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: top .5s;
}

.linkBtnWrap {
  display: inline-block;
  position: relative;

  .textLink {
    font-family: Poppins-Regular, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $brand;
    }
  }
}

.btnUnderline {
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  width: 20px;
  height: 10px;
  margin: auto;
  svg path {
    fill: var(--firstMenuFontHoverColor);
  }
}

.actionButton {
  font-family: Poppins-Regular, sans-serif;
  font-weight: 600;
  font-size: 16px;
  height: 45px;
  border-radius: 34.5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 26px;
  color: $white-text;
  background-color: $brand;
  border: 1px solid $brand;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    border-color: $primary-text;
    background-color: $primary-text;
  }
}

.menuIcon {
  display: none;
  svg path {
    stroke: $primary-text;
  }
}

.menus {
  display: flex;
  align-items: center;
  border-radius: 101.25px;
  gap: 68px;
  padding:  6px 7.47px 6px 58px;
  background-color: #fff;

  .firstMenuWithChild {
    font-family: Poppins-Regular, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    svg {
      margin-left: 12px;
      @include rotate(0, 0.2s);
      path {
        fill: var(--firstMenuDefaultFontColor);
      }
    }

    &:hover {
      color: $brand;
      svg path {
        fill: $brand;
      }
    }
  }

  .rotateSvg {
    svg {
      @include rotate(180deg, 0.2s);
    }
  }
}

.secondMenus {
  top: 62px !important;
  :global {
    .ant-popover-arrow {
      top: 1px !important;
    }
    .ant-popover-arrow-content {
      box-sizing: content-box;
      border: 1.5px solid var(--border) !important;
    }
    .ant-popover-arrow-content::before {
      background-color: var(--normalBg) !important;
    }
    .ant-popover-inner-content {
      background-color: var(--normalBg) !important;
      border-radius: 7px;
      border: 1px solid var(--border) !important;
      overflow: hidden;
    }
    .ant-popover-inner {
      background: none !important;
      overflow: hidden !important;
      border-radius: 6px;
    }
  }
  .secondMenuList {
    padding: 9px 7px;
    width: 178px;
    .secondMenuItem {
      display: flex;
      align-items: center;
      gap: 14px;
      width: 100%;
      padding: 6px 11px;
      font-weight: 500;
      font-family: Poppins-Regular, sans-serif;
      font-size: 18px;
      color: #000;
      border-radius: 2px;
      margin-bottom: 4px;
      cursor: pointer;

      svg path {
        fill: $primary-text;
      }

      &:hover {
        color: $brand;
        background: $hover-bg;

        svg path {
          fill: $brand;
        }
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $divide-991) {
  .menuIcon {
    display: block;
  }
  .menus, .header__links {
    display: none;
  }
  
  .navHeader {
    padding-left: 30px;
    padding-right: 10px;
    background: $white-bg;
    border-radius: 40px;
    height: 53px;
  }

  .logoWarpImg {
    width: 106px !important;
    height: 17.87px !important;
    img {
      width: auto !important;
      height: 17.87px !important;
    }
  }

  .logoWrap {
    padding-left: 32px;
    padding-right: 32px;
    height: 53px;
  }
  
  .navBlackHeader {
    padding: 15px 0;
    background: transparent;

    .actionButton {
      width: auto !important;
      font-size: 12px;
      height: 38px;
      border-radius: 29.475px !important;
      padding: 0 22px;
    }
  }
}



@media screen and (max-width: $divide-767) {
  
  .menuIcon {
    display: block;
  }
  .menus {
    display: none;
  }

  .navHeader {
    padding-left: 30px;
    padding-right: 5px;
    background: $white-bg;
    border-radius: 90px;
    height: 46px;
  }

  .logoWrap {
    padding-left: 32px;
    padding-right: 12px;
    height: 46px;
  }
  
  .navBlackHeader {
    padding: 15px 0;
    background: transparent;

    .actionButton {
      width: auto !important;
      font-size: 12px;
      height: 38px;
      border-radius: 29.475px !important;
      padding: 0 22px;
    }
  }
}
