.font-family-semibold {
  font-weight: bold;
  font-family: Roboto-Bold, PingFangSC-Semibold, PingFang SC, sans-serif;
}
.font-family-medium {
  font-weight: 500;
  font-family: Roboto-Medium, PingFangSC-Medium, PingFang SC, sans-serif;
}
.font-family-regular {
  font-weight: 400;
  font-family: Roboto-Regular, PingFangSC-Regular, PingFang SC, sans-serif;
}

/** Poppins-Bold */
@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/** Poppins-Regular */
@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}