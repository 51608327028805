/* stylelint-disable selector-class-pattern */
@import '/src/assets/theme/color.scss';
@import '/src/assets/theme/constants.scss';

.footer {
  padding-top: 40px;
  padding-bottom: 22px;
  min-width: $page-min-width;

  .footerBody {
    width: 100%;
  }

  .footerNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    flex-wrap: wrap;
    overflow-x: hidden; // Prevent horizontal scrolling caused by too long menu title
    .footerLogoWrapper {
      gap: 12px;
      margin-bottom: 8px; // 40px
    }

    .logo {
      cursor: 'pointer';
    }

    .mobileLogo {
      display: none;
      width: 27.67px;
      height: auto;
    }

    .footerDescription {
      color: $secondary-text;
      font-weight: 400;
      font-family: Poppins-Regular, sans-serif;
      font-size: 14px;
      line-height: 22px;
    }

    .menus {
      flex-wrap: wrap;
      gap: 100px;
      // gap: calc((100% - 600px) / 4);

      .menuGroup {
        width: 135px;
        overflow-x: hidden;
        .firstMenus {
          font-family: Poppins-Regular, sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: $primary-text;
          margin-bottom: 27px;
        }
        .secondMenus {
          display: flex;
          align-items: center;
          gap: 18px;
          font-family: Poppins-Regular, sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondMenuDefaultFontColor);
          margin-bottom: 18px;
          cursor: pointer;

          .secondMenuItemMIcon {
            display: none;
          }

          svg path {
            fill: #808080;
          }
          &:hover {
            color: $brand;

            svg path {
              fill: $brand;
            }
          }
        }
      }
    }
  }

  .footerMedia {
    height: 64px;
    border-top: 1px solid $dividers;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .iconBase {
      width: 24px;
      height: 24px;
      margin-right: 22px;
      cursor: pointer;
    }

    .footerPower {
      font-size: 14px;
      line-height: 22px;
      color: $disable-text;
    }
  }

  @media screen and (max-width: $divide-991) {
    .footerNav {

      .footerDescription {
        max-width: 288px;
      }
    }
  }

  @media screen and (max-width: $divide-767) {
    padding-top: 24px;
    .footerNav {
      .footerLogoWrapper {
        margin-bottom: 40px;
      }

      .logo {
        display: none;
      }

      .mobileLogo {
        display: block;
      }

      .footerDescription {
        display: none;
      }

      .menus {
        flex-wrap: wrap;
        gap: 24px;
  
        .menuGroup {
          width: 110px;
          overflow-x: hidden;
          .firstMenus {
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            color: $primary-text;
            margin-bottom: 6px;
          }

          .secondMenus {
            font-weight: 400;
            font-size: 12px;
            line-height: 21px;
            color: $secondary-text;
            margin-bottom: 5px;
            .secondMenuItemIcon {
              display: none;
            }
    
            .secondMenuItemMIcon {
              display: block !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $divide-579) {
    .footerMedia {
      height: 94px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 20px;

      .footerPower {
        margin-top: 16px;
      }
    }
  }
}
